import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/consumption';
import { isMock, consumption } from '../constants/utils/mock';

const initState = {
    isLoading: [],
    statistics: !isMock ? undefined : consumption,
    error: undefined,
};

const ConsumptionReducer = createSlice({
    name: 'consumptionState',
    initialState: { ...initState },
    reducers: {
        //#region GET CONSUMPTION
        getConsumptionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_CONSUMPTION)) {
                state.isLoading.push(Loadings.FETCHING_CONSUMPTION);
            }
        },
        getConsumptionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONSUMPTION);
            state.statistics = payload;
        },
        getConsumptionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONSUMPTION);
            state.statistics = payload;
        },
        //#endregion

        //#region UPDATE CONSUMPTION
        updateConsumptionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_CONSUMPTION)) {
                state.isLoading.push(Loadings.UPDATING_CONSUMPTION);
            }
        },
        updateConsumptionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_CONSUMPTION);
            state.statistics = payload;
        },
        updateConsumptionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_CONSUMPTION);
            state.isLoading = false;
        },
        //#endregion

        //#region UPDATE CONSUMPTION
        resetConsumptionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.RESETING_CONSUMPTION)) {
                state.isLoading.push(Loadings.RESETING_CONSUMPTION);
            }
        },
        resetConsumptionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.RESETING_CONSUMPTION);
            state.statistics.consumptions = payload;
        },
        resetConsumptionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.RESETING_CONSUMPTION);
            state.isLoading = false;
        },
        //#endregion
    }
});

export const consumptionActions = ConsumptionReducer.actions;
export default ConsumptionReducer.reducer;