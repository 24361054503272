import React from 'react';

export const Footer = () => {
    return (
        <footer className="flex-row" role="main">
            <p className="text-body tex-opacity-50 fs-sm mb-n1">2024 © Copyright <strong>Securibox</strong>. Tous droits réservés</p>
        </footer>
    );
};
export default Footer;
