import { Slide } from 'react-toastify';
export const toaster = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    icon: false,
    theme: "colored",
}

export const toasterDown = {
    position: "bottom-center",
    autoClose: 300,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    icon: false,
    transition: Slide,
    theme: "dark",
}