import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/authentication';
import Translate from '../constants/translate';
import { isMock, user } from '../constants/utils/mock';

const initState = {
    isLoading: [],
    user: undefined,
    error: undefined
};

const AuthenticationReducer = createSlice({
    name: 'authenticationState',
    initialState: { ...initState },
    reducers: {
        //#region GET AUTHENTICATION
        getUserRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_USER)) {
                state.isLoading.push(Loadings.FETCHING_USER);
            }
        },
        getUserSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_USER);
            state.user = {
                ...state.user,
                firstName: payload.given_name,
                lastName: payload.family_name,
                language: payload.locale || Translate.default,
                email: payload.email,
            };
        },
        getUserFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_USER);
            state.user = false;
        },
        //#endregion
        //#region GET CODE
        getCodeAuthenticationRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_CODEAUTHENTICATION)) {
                state.isLoading.push(Loadings.FETCHING_CODEAUTHENTICATION);
            }
        },
        getCodeAuthenticationSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CODEAUTHENTICATION);
        },
        getCodeAuthenticationFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CODEAUTHENTICATION);
        },
        //#endregion
    }
});

export const authenticationActions = AuthenticationReducer.actions;
export default AuthenticationReducer.reducer;