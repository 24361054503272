export const Delete = {'en-EN': "Delete", 'fr-FR':"Suppression", 'pt-PT':"Apagar"};
export const DeleteList_1 = {'en-EN': "Once you delete your account:", 'fr-FR':"Si vous supprimez votre compte:", 'pt-PT':"Ao apagar a sua conta:"};
export const DeleteList_2 = {'en-EN':"All information associated with this account will be permanently removed from the services (CloudAgents, ParseXtract, Cube, RH), without recovery possibility.", 'fr-FR':"Toutes les informations dans les services (CloudAgents, ParseXtract, Cube, RH) associés à ce compte seront définitivement supprimées et ne pourront plus être récupérées.", 'pt-PT':"Toda a informação associada a esta conta será permanentemente removida dos serviços (CloudAgents, ParseXtract, Cube, RH), sem possibilidade de recuperação."};
export const DeleteList_3 = {'en-EN':"The data stored at the external cloud storages (Google Drive, OneDrive, Box), associated with this account or any of its services, will not be removed.", 'fr-FR':"Les données stockées dans des espaces cloud externes (Google Drive, OneDrive, Box) qui ont été associés à ce compte Securibox, ou à un des services associés, ne seront bien sûr pas supprimées.", 'pt-PT':"Os dados armazenados nos espaços de armazenamento em nuvem externos (Google Drive, OneDrive, Box), associados a esta conta ou a qualquer um dos seus serviços, não serão removidos."};
export const DeleteList_4 = {'en-EN':"Before proceeding, export all your information from the services associated with this account.", 'fr-FR': "Exportez antérieurement toutes vos informations à partir des différents services associés à ce compte.", 'pt-PT':"Antes de prosseguir, exporte todas as suas informações dos serviços associados a esta conta."};
export const DeleteButton = {'en-EN':"I want to delete my account", 'fr-FR':"Je veux supprimer mon compte", 'pt-PT':"Quero apagar a minha conta"};
export const DeleteAccount = {'en-EN': "Delete account", 'fr-FR':"Supprimer compte", 'pt-PT':"Apagar conta"};
export const DeletePartner = {'en-EN': "Delete partner", 'fr-FR':"Supprimer partenaire", 'pt-PT':"Apagar parceiro"};
export const DeleteInfo = {'en-EN': "Delete info", 'fr-FR':"Supprimer information", 'pt-PT':"Apagar info"};

export const DeleteTitle = {'en-EN':"We are sorry to see you go.", 'fr-FR':"Nous sommes désolés de vous voir partir.", 'pt-PT':"Lamentamos vê-lo partir."};
export const DeleteText = {'en-EN':"All information associated will be permanently removed", 'fr-FR':"Toutes les informations associées seront définitivement supprimées", 'pt-PT':"Todas as informações associadas serão removidas permanentemente"};
export const DeleteAccountText = {'en-EN':"Delete my account", 'fr-FR':"Suppression de compte", 'pt-PT':"Apagar a minha conta"};
export const DeletePartnerText = {'en-EN':"Delete this partner", 'fr-FR':"Supprimer ce partenaire", 'pt-PT':"Excluir este parceiro"};
export const DeleteCompteEchoText = {'en-EN':"Delete my ECHO account", 'fr-FR':"Supprimer mon compte ECHO", 'pt-PT':"Excluir a minha compta ECHO"};
export const DeleteWarning = {'en-EN':"Warning: This deletion will be permanent without any recovery possibility. Are you sure you want to continue?", 'fr-FR':"Attention: cette suppression est définitive et les informations ne pourront pas être récupérées. êtes-vous sûr de vouloir continuer?", 'pt-PT':"Aviso: Esta eliminação será permanente e sem possibilidade de recuperação. Tem certeza de que deseja continuar?"};
export const DeleteConfirm = {'en-EN':"Yes, delete", 'fr-FR':"Oui, je veux supprimer", 'pt-PT':"Sim, quero apagar"};