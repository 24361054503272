import React from "react";
import { Link } from "react-router-dom";

export const NoMatch = ({ redirect, dismiss, margin, action }) => {
    const dismissModal = (e) => {
        e.target.setAttribute("data-bs-dismiss", dismiss);
        e.target.click();
        e.target.removeAttribute("data-bs-dismiss");
    };

    return (
        <main className={"card flex-grow-1 px-4 py-5 text-center border-0 p-2 gap-2 bg-warning-subtle" + (margin ? (" " + margin) : "")} role="main">
            <div className="container m-auto">
                <small className="smallcaps fw-bold ff-aux">Error 404</small>
                <h2 className="display-1 fw-bold mb-3 mt-n2 ff-aux text-warning">Oops! 😳</h2>
                <p className="lead fw-normal ff-aux mb-2">Sorry, it seems that the page you were seeking is not accessible.</p>
                <p className="text-body-secondary mb-6 container-wide mx-auto">The site configured at this address does not contain the requested file. The page you're looking for does not exist or is not available. <br />
                </p>
                <Link className="btn btn-warning rounded-1 px-4" to={redirect} onClick={dismissModal} >Refresh</Link>
            </div>
        </main>
    );
};