import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Constants
import * as LoadingsPartner from "../../constants/loadings/partners";
import * as LoadingsConsumption from "../../constants/loadings/consumption";

// Components: Common
import { GetPartner } from "../../actions/partners-api";
import { GetConsumption } from '../../actions/consumption-api';

// Components: Views
import Partner from './partner';
import Consumption from './consumption';
import ModalDelete from './modalDelete';

export const Keys = ({ authenticationState, partnersState, companiesState, consumptionState }) => {
    const dispatch = useDispatch();
    const partnerID = partnersState.partner?.id || null;

    // useEffect(() => {
    //     if (R.isNil(partnersState.partner)) {
    //         dispatch(GetPartner(partnerID));
    //         dispatch(GetConsumption(partnerID));
    //     }
    //     // eslint-disable-next-line
    // }, [partnersState.partner]);


    return (
		<React.Fragment>
			<Partner partnersState={partnersState} consumptionState={consumptionState} authenticationState={authenticationState} companiesState={companiesState} />
			<Consumption partnersState={partnersState} consumptionState={consumptionState} authenticationState={authenticationState} companiesState={companiesState} />
			<ModalDelete partnerID={partnerID} partnersState={partnersState} authenticationState={authenticationState} companiesState={companiesState} />
		</React.Fragment>
		);
};


export default Keys;