import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'bootstrap';
import { jwtDecode } from 'jwt-decode';
import * as R from "ramda";

// Components
// ==========================================
// Components: Constants
import Translate from '../../constants/translate';
import { EndPoints } from '../../constants/api-config';
import * as LoadingsAuthentication from '../../constants/loadings/authentication';

// Components: Assets
import Logo from '../../assets/images/brand/securibox-icon.svg?react';
import Avatar from '../../assets/images/placeholders/app/avatar.svg?react';

export const Navbar = ({ authenticationState }) => {
    const id_token = sessionStorage.getItem("id_token");
    const logoutUrl = "https://" + (EndPoints.environment === "production" ? "accounts" : "accounts-dev") + ".securibox.eu/id/connect/endsession?idToken_hint=" + jwtDecode(id_token);

    return (
        <nav className="navbar navbar-expand-md navbar-light sbx-navbar border-bottom border-top border-faded">
            <h4 className="sr-only">Navigation</h4>
            <div className="container-fluid px-0">
                {/* <button className="btn navbar-toggler border-none outline-none me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="icns material-symbols-rounded"> menu </i>
                </button> */}
                <Link className="navbar-brand py-0 text-body me-auto me-lg-1 flex-row align-items-center gap-2" to="/">
                    <Logo width="32" alt="Securibox" className="img-fluid align-middle text-inherit me-2" />
                    <span className="text-uppercase fs-book fw-bold ls-4 text-dark">ECHO</span>
                </Link>
                <ul className="navbar-nav flex-row order-md-last align-items-center">
                    <li className="nav-item me-2">                    
                        <Link className="btn btn-light text-bg-primary-hover border-primary-hover rounded-1 p-1 fs-md fw-demi" to="https://www.securibox.eu/docs/echo/#section/Portail-ECHO" target="_blank" rel="noopener noreferrer"  >
                            <i className="icns material-symbols-rounded align-middle fs-root"> contact_support </i>
                            <span className="mx-2 align-middle">{Translate.Help[authenticationState.user?.language]}</span>
                        </Link>

                        {/* <a className="btn btn-light rounded-1 p-1 fs-md fw-demi" target="blank" href="mailto:support@securibox.eu?Subject=Securibox%20Echo%20-%20Help%20" rel="noopener noreferrer" tabIndex={-1} aria-disabled="true">
                            <i className="icns material-symbols-rounded align-middle fs-root"> contact_support </i>
                            <span className="mx-2 align-middle">{Translate.Help[authenticationState.user?.language]}</span>
                        </a> */}
                    </li>
                    <li className="nav-item dropdown">
                        <button className="btn nav-link lh-1 p-2 mx-auto align-items-center border-none" type="button" onClick={(e) => {
                            e.preventDefault();
                            const dropdown = new Dropdown(document.getElementById("dropdown-account"));
                            dropdown.toggle();
                        }}>
                            <Avatar height={32} width={32} alt="avatar" className="img-fluid obj-fit align-middle text-body o-88-hover flex-shrink-0" />
                            <span className="visually-hidden align-middle mx-2">Account</span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end shadow-lg border-4 border-solid border-white position-absolute" aria-labelledby="dropdown-account" id="dropdown-account" aria-label="Informações da conta" aria-hidden="false">
                            <div className="dropdown-header flex-column mw-tiny align-items-center justify-content-center bg-body-tertiary py-1 rounded-1 mb-1">
                                <div className="flex-column min-w-min mw-100 text-body align-items-center gap-2 rounded-1 my-3">
                                    <div className="avatar avatar-message flex-shrink-0 text-body bg-white rounded-1" aria-label="Modifier a imagem do perfil.">
                                        <div className="fs-md text-uppercase text-center fw-black" title="Avatar name">{authenticationState.user?.firstName?.charAt(0) || 'AN'}{authenticationState.user?.lastName?.charAt(0) || ''}</div>
                                        <span className="account-change sr-only">Modifier</span>
                                    </div>
                                    <div className="flex-column mw-100 align-items-center justify-content-center">
                                        <div className="fs-lg fw-demi mt-n1 mb-0 text-truncate pe-2">{authenticationState.user?.firstName || 'Account Name'} {authenticationState.user?.lastName || ''}</div>
                                        <div className="mt-n1 text-truncate fs-md pe-1 mw-inherit">{authenticationState.user?.email || 'N/A'}</div>
                                    </div>
                                </div>
                                <a className="btn btn-outline-dark fs-md py-g1 px-g2 rounded-pill mb-3 mt-n2" href="https://me.securibox.eu" target="_blank" rel="noreferrer">{Translate.ManageAccount[authenticationState.user?.language]}</a>
                            </div>
                            <button className="btn fw-demi fs-aux text-body p-1 text-opacity-44 text-opacity-100-hover w-100 rounded-1" onClick={(e) => {
                                e.preventDefault();
                                window.location = logoutUrl;
                                localStorage.removeItem("id_token");
                                localStorage.removeItem("access_token");
                            }}>
                                <span> {Translate.Logout[authenticationState.user?.language]} </span>
                                {/* {   authenticationState.isLoading.includes(LoadingsAuthentication.LOGGING_OUT) ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : null
                            } */}
                            </button>
                        </ul>
                    </li>
                </ul>
                {/* <div className="navbar-nav-scroll accounts-container mx-md-auto navbar-collapse collapse" id="navbar">
                    <ul className="navbar-nav me-md-auto">
                        <li className="nav-item"><NavLink className="nav-link fw-bold ls-3" to="/"><i className="icns text-primary h5 mb-0 mdi mdi-home-outline align-middle ms-n2"></i> ECHO <span className="sr-only">(current)</span></NavLink></li>
                        <li className="nav-item"><NavLink className="nav-link fw-bold ls-3 text-uppercase"to="/services"><i className="icns text-primary h5 mb-0 mdi mdi-apps align-middle ms-n2"></i> Services</NavLink></li>
                    </ul>
                </div> */}
            </div>
        </nav>
    );
};


export default Navbar;