import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Constants
import Translate from '../../constants/translate';
import * as LoadingsPartners from '../../constants/loadings/partners';
import { toaster } from "../../constants/utils/toaster";

// Components: Common
import { DeletePartnerTechnicalAccount, GetPartnerTechnicalAccounts } from '../../actions/partners-api';


export const ModalDeleteTechnicalAccount = ({partnerID, selectedTechnicalAccounts, authenticationState}) => {
    const closeRef = useRef();
    const dispatch = useDispatch();

    const handleDeleteTechnicalAccount = async (e) => {
        e.preventDefault();
        try {
            await dispatch(DeletePartnerTechnicalAccount(partnerID, btoa(selectedTechnicalAccounts.username + ":" + selectedTechnicalAccounts.secret))).then(() => {
                dispatch(GetPartnerTechnicalAccounts(partnerID));
                closeRef.current.click();
                toast.info(Translate.Success[authenticationState.user?.language], toaster);
            });
        } catch (error) {
            toast.error(Translate.Error[authenticationState.user?.language], toaster);
        }
    };

    return (
        <div className="modal" id={"deleteTechnicalAccount" + selectedTechnicalAccounts.siret} tabIndex={-1} aria-labelledby={"deleteTechnicalAccount" + selectedTechnicalAccounts.siret + "Label"} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content rounded">
                    <div className="modal-header">
                        <h5 className="modal-title small text-body-secondary" id="authenticationRemoveLabel">{Translate.Delete[authenticationState.user?.language]} {Translate.KeyId[authenticationState.user?.language]}</h5>
                        <button type="button" className="btn-close fw-light shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                            <span aria-hidden="true" className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body py-3 pt-lg-5 text-center">
                        <span className="icns material-symbols-rounded align-middle display-2 lh-1 text-danger mb-3">
                            delete
                        </span>
                        <div className="ff-aux fs-3 ls-n1 fw-black">{Translate.Delete[authenticationState.user?.language]} <span className="text-danger">{selectedTechnicalAccounts.name}</span></div>
                        <p className="lh-base fs-root mb-0 text-body-secondary">{Translate.DeleteWarning[authenticationState.user?.language]}</p>
                    </div>
                    <div className="modal-footer flex-column">
                        <button type="button" className="btn btn-danger rounded-pill px-g2 flex-row align-items-center gap-2" onClick={(event) => { handleDeleteTechnicalAccount(event); }}>
                            {Translate.DeleteConfirm[authenticationState.user?.language]}
                        </button>
                        <button type="button" className="btn py-0 text-body text-opacity-75 text-opacity-50-hover" data-bs-dismiss="modal">
                            {Translate.Cancel[authenticationState.user?.language]}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDeleteTechnicalAccount;