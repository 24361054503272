let endpoints = {
    // backendHost: window.env['APP_DOMAIN'],
    backendHost: 'https://localhost:44319',
    environment: 'development'
};

const hostname = window && window.location && window.location.hostname;
if (hostname === 'echo.securibox.eu') {
    endpoints.backendHost = 'https://echo-api.securibox.eu';
    endpoints.environment = 'production';
}
else {
    endpoints.backendHost = 'https://echo-api-stg.securibox.eu';
    endpoints.environment = 'development';
}
export const EndPoints = endpoints;

