import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Constants
import Translate from '../../constants/translate';
import * as LoadingsPartners from '../../constants/loadings/partners';
import { toaster } from "../../constants/utils/toaster";

// Components: Common
import { DeletePartner, GetPartners } from '../../actions/partners-api';
// import { deletePartners } from '../../../actions/authentication-api';


export const ModalDelete = ({partnerID, partnersState, authenticationState}) => {
    const closeRef = useRef();
    const dispatch = useDispatch();

    const handleDeleteUser = async (e) => {
        e.preventDefault();
        try {
            await dispatch(DeletePartner(partnerID)).then(() => {
                toast.info(Translate.Success[authenticationState.user?.language], toaster);
                closeRef.current.click();
                dispatch(GetPartners());
                // sessionStorage.removeItem("id_token");
                // sessionStorage.removeItem("access_token");
            });
        } catch (error) {
            // console.log(error)
            toast.error(Translate.Error[authenticationState.user?.language], toaster);
        }
    };

    return (
        <div className="modal" id="authenticationRemove" tabIndex={-1} aria-labelledby="authenticationRemoveLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content shadow-lg rounded-sm">
                    <div className="modal-header">
                        {/* <h5 className="modal-title small text-body-secondary" id="authenticationRemoveLabel">{Translate.DeleteTitle[authenticationState.user?.language]}</h5> */}
                        <button type="button" className="btn-close fw-light shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                            <span aria-hidden="true" className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body py-3 pt-lg-5 text-center">
                        <span className="icns material-symbols-rounded align-middle display-2 lh-1 text-danger mb-3">
                            delete
                        </span>
                        <div className="ff-aux fs-3 ls-n1 fw-black">{Translate.DeleteCompteEchoText[authenticationState.user?.language]}</div>
                        <p className="text-body-secondary">{Translate.DeleteWarning[authenticationState.user?.language]}</p>
                    </div>
                    <div className="modal-footer flex-column justify-content-center">
                        <button type="button" disabled={partnersState.isLoading.includes(LoadingsPartners.DELETING_PARTNER)} className="btn btn-danger rounded-pill px-g2 flex-row align-items-center gap-2" onClick={(event) => { handleDeleteUser(event); }}>
                            {Translate.DeleteConfirm[authenticationState.user?.language]}
                            {   partnersState.isLoading.includes(LoadingsPartners.DELETING_PARTNER) ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : null
                            }
                        </button>
                        <button type="button" className="btn py-0 text-body text-opacity-75 text-opacity-50-hover" data-bs-dismiss="modal">{Translate.Cancel[authenticationState.user?.language]}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDelete;