import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import authenticationState from './authentication';
import consumptionState from './consumption';
import companiesState from './companies';
import partnersState from './partners';

export const store = configureStore({
    reducer: {
        authenticationState,
        consumptionState,
        companiesState,
        partnersState,
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => {
        let options = { serializableCheck: false };
        if (process.env.NODE_ENV !== 'production')
            return getDefaultMiddleware(options).concat(logger);

        return getDefaultMiddleware(options);
    }
});
