import React, { useState, useRef, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import * as R from "ramda";

// Components
// ==========================================
// Components: Constants
import Translate from '../../constants/translate';
import { flowStyles } from "../../constants/utils/style";
import { technicalAccounts } from "../../constants/utils/mock";
import { toaster, toasterDown } from "../../constants/utils/toaster";
import { getAuthenticationUrl } from "../../constants/webapi/authentication";
import * as LoadingsPartner from "../../constants/loadings/partners";
import * as LoadingsConsumption from "../../constants/loadings/consumption";

// Components: Utils
import { Loading } from "../../_common/loading";
import { GetCompanies } from "../../actions/companies-api";
import { UpdatePartner, CreatePartner, GetPartnerSecretNew, GetPartnerTechnicalAccounts } from "../../actions/partners-api";
import { resetConsumption, GetConsumption } from "../../actions/consumption-api";

// Components: Views
import { Fallback } from "../_common/fallback";
import Key from "../../assets/images/placeholders/app/step.svg?react";

import ModalCreateTechnicalAccount from "./modalCreateTechnicalAccount";
import ModalDeleteTechnicalAccount from "./modalDeleteTechnicalAccount";
import { SECTORS } from "../../constants/utils/sectorMapper";



export const Partner = ({ partnersState, authenticationState, companiesState, consumptionState }) => {
    const dispatch = useDispatch();

    const [selectedPartner, setSelectedPartner] = useState(partnersState.partner);
    const [showModify, setShowModify] = useState(false);
    const [showApiSecret, setShowApiSecret] = useState(false);
    const [showTechnicalAccounts, setShowTechnicalAccounts] = useState(false);
    const [selectedTechnicalAccounts, setSelectedTechnicalAccounts] = useState(false);
    const partnerRef = useRef();
    const technicalAccountRef = useRef();
    const apiKeyRef = useRef();

    const flowColumns = [
        {
            name: 'Siren',
            selector: row => row.siren || 'N/A',
            grow: 0, // Let the column grow to fill available space
        },
        {
            name: 'Société',
            style: { width: '10em' },
            selector: row => row.name || 'Undefined',
            grow: 0,
        },
        {
            name: 'Identifiant',
            // style: {width: '25em'},
            selector: row => row.username || '#',
            grow: 1,
        },
        {
            name: 'Mot de passe',
            selector: row => row.secret || 'Undefined',
        },
        {
            style: { maxWidth: '3em', minWidth: '3em' },
            grow: 0, // Do not let this column grow
            cell: row => (
                <button type="button" className="btn btn-sm rounded-1 text-uppercase ff-base text-truncate text-danger bg-error text-bg-danger-hover ms-auto" data-bs-toggle="modal" data-bs-target={"#deleteTechnicalAccount" + row.siret} data-tag="allowRowEvents" onClick={() => setSelectedTechnicalAccounts(row)}>
                    <span className="icns material-symbols-rounded align-middle lh-inherit fs-lg">delete</span>
                </button>
            )
        },
    ];

    useEffect(() => {
        setSelectedPartner({ ...partnersState.partner });
    }, [partnersState.partner]);

    useEffect(() => {
        if (selectedTechnicalAccounts) {
            const modalElement = document.getElementById("deleteTechnicalAccount" + selectedTechnicalAccounts.siret);
            const modal = new Modal(modalElement);
            modal.show();
        }
    }, [selectedTechnicalAccounts]);


    const submitPartner = async (e) => {
        e.preventDefault();
        partnerRef.current.classList.add('was-validated');
        if (R.isEmpty(selectedPartner)) return;
        if (partnerRef.current.checkValidity()) {
            try {
                if (R.isNil(selectedPartner?.id)) {
                    await dispatch(CreatePartner({ ...selectedPartner })).then((response) => {
                        dispatch(GetCompanies(response.id));
                        dispatch(GetPartnerTechnicalAccounts(response.id));
                        dispatch(GetConsumption(response.id));
                    });
                } else {
                    await dispatch(UpdatePartner(selectedPartner?.id, { ...selectedPartner }));
                }
                partnerRef.current.classList.remove('was-validated');
                toast.info(Translate.SuccessAPIKey[authenticationState.user?.language], toaster);
                setShowModify(false);
            } catch (error) {
                toast.error(Translate.Error[authenticationState.user?.language], toaster);
            }
        }
    };

    const submitApiKey = async (e) => {
        e.preventDefault();
        apiKeyRef.current.classList.add('was-validated');
        if (R.isEmpty(selectedPartner)) return;
        if (apiKeyRef.current.checkValidity()) {
            try {
                await dispatch(GetPartnerSecretNew(selectedPartner.id));
                apiKeyRef.current.classList.remove('was-validated');
                toast.info(Translate.SuccessAPIKey[authenticationState.user?.language], toaster);
            } catch (error) {
                toast.error(Translate.Error[authenticationState.user?.language], toaster);
            }
        }
    };

    const handleResetConsumption = async (e) => {
        e.preventDefault();
        if (R.isEmpty(selectedPartner)) return;
        try {
            await dispatch(resetConsumption(selectedPartner?.id));
            toast.info(Translate.Success[authenticationState.user?.language], toaster);
        } catch (error) {
            toast.error(Translate.Error[authenticationState.user?.language], toaster);
            console.error("Error resetting consumption:", error);
            // You can add error handling logic here, such as displaying an error message
        }
    };



    return (
        partnersState.isLoading.includes(LoadingsPartner.FETCHING_PARTNER) ?
            <Loading />
            :
            R.isNil(authenticationState.user) ?
                <Fallback margin="grid grid-auto" onClick={() => { window.location = getAuthenticationUrl(); }} />
                :
                <article className="grid gap-3 gap-g2">
                    {/* <button className="btn btn-warning" onClick={() => { window.location = getAuthenticationUrl(); }}>TEST getAuthenticationUrl()</button> */}
                    <header className="flex-column flex-sm-row flex-md-column flex-lg-row gap-3">
                        <div className="block avatar avatar-4 flex-shrink-0">
                            <Key height={64} width={64} alt="avatar" className="img-fluid obj-fit align-middle text-body o-88-hover flex-shrink-0" />
                            <span className="visually-hidden align-middle mx-2">Page</span>
                        </div>
                        <div className="row row-cols-sm-2 align-items-center g-2 flex-fill">
                            <div className="col order-sm-first">
                                <h2 className="fs-3 fw-black ls-n1 text-body my-auto">{Translate.AccessAPI[authenticationState.user?.language]}</h2>
                            </div>
                            <div className="col-12 flex-fill order-sm-last">
                                <p className="fw-medium mb-0 container-base text-body text-opacity-75">{Translate.KeysWelcome[authenticationState.user?.language]}</p>
                                <Link className="btn fw-demi fs-aux text-body p-0 text-opacity-44 text-opacity-100-hover me-auto ls-na ls-sm-none" to="https://www.securibox.eu/docs/echo/" target="_blank" rel="noopener noreferrer">{Translate.KeysDocumentation[authenticationState.user?.language]} →</Link>
                            </div>
                            <div className="col-12 flex-fill col-sm text-sm-end">
                                <button className="btn bg-body-tertiary bg-opacity-66 bg-opacity-44-hover fw-demi fs-aux text-body rounded-3 text-opacity-44 text-opacity-66-hover" data-bs-toggle="modal" data-bs-target="#authenticationRemove">
                                    <span className="align-middle mx-1">
                                        {Translate.Delete[authenticationState.user?.language]}
                                    </span>
                                    <span className="icns material-symbols-rounded align-middle fs-lg mt-bw">
                                        delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    </header>
                    <div className="card rounded-3 p-3 bg-primary bg-opacity-5 flex-column gap-3 mb-auto">
                        <form className={"card p-3 p-xl-2 p-uhd-3 gap-3 flex-column" + (R.isNil(selectedPartner?.apiKey) ? " needs-validation" : " bg-none disabled")} noValidate id="selectedPartnerForm" onSubmit={submitPartner} ref={partnerRef}>
                            <section className="flex-column gap-3">
                                <h2 className="visually-hidden">Company</h2>
                                <div className="flex-column flex-xl-row gap-3">
                                    <div className="form-group flex-fill">
                                        <label htmlFor="contactCompanyNameInput" className="form-label fw-demi text-body text-opacity-44">{Translate.KeysFormCompany[authenticationState.user?.language]} <strong className="text-body text-opacity-100 fs-md">*</strong></label>
                                        <input type="text" className="form-control rounded-2 py-3 px-4 fw-medium bg-common bg-opacity-5 border-none text-body" id="contactCompanyNameInput" placeholder="Type here..." disabled={R.isNotNil(partnersState.partner) && showModify === false ? true : false} value={selectedPartner?.name || ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, name: e.target.value }); }} required />
                                        <div className="invalid-feedback">{Translate.KeysFormCompanyFeedback[authenticationState.user?.language]}</div>
                                    </div>
                                    <div className="form-group flex-fill">
                                        <label htmlFor="contactAreaInput" className="form-label fw-demi text-body text-opacity-44">{Translate.KeysFormSector[authenticationState.user?.language]}</label>
                                        <select className="form-select rounded-2 py-3 px-4 fw-medium bg-common bg-opacity-5 border-none text-body" aria-label="Default select example" id="contactAreaInput" disabled={R.isNotNil(partnersState.partner) && showModify === false ? true : false} value={R.isNotNil(selectedPartner?.sector) && R.isNotNil(SECTORS[selectedPartner?.sector]) ? SECTORS[selectedPartner?.sector]['en-EN'] : ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, sector: e.target.value }); }} required>
                                            {/* <select className="form-select rounded-2 py-3 px-4 fw-medium bg-common bg-opacity-5 border-none text-body" aria-label="Default select example" id="contactAreaInput" disabled={R.isNotNil(partnersState.partner) && showModify === false ? true : false} value={selectedPartner?.sector || ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, sector: e.target.value }); }} required> */}
                                            <option value="">{Translate.KeysFormSectorList_Select[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Fintech['en-EN']}>{Translate.KeysFormSectorList_Fintech[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Banque['en-EN']}>{Translate.KeysFormSectorList_Banque[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Finance['en-EN']}>{Translate.KeysFormSectorList_Finance[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Services['en-EN']}>{Translate.KeysFormSectorList_Services[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Industrie['en-EN']}>{Translate.KeysFormSectorList_Industrie[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Telecommunication['en-EN']}>{Translate.KeysFormSectorList_Telecommunication[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Construction['en-EN']}>{Translate.KeysFormSectorList_Construction[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Immobilier['en-EN']}>{Translate.KeysFormSectorList_Immobilier[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Énergie['en-EN']}>{Translate.KeysFormSectorList_Énergie[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Commerce['en-EN']}>{Translate.KeysFormSectorList_Commerce[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Agriculture['en-EN']}>{Translate.KeysFormSectorList_Agriculture[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Transport['en-EN']}>{Translate.KeysFormSectorList_Transport[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Tourisme['en-EN']}>{Translate.KeysFormSectorList_Tourisme[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Santé['en-EN']}>{Translate.KeysFormSectorList_Santé[authenticationState.user?.language]}</option>
                                            <option value={Translate.KeysFormSectorList_Other['en-EN']}>{Translate.KeysFormSectorList_Other[authenticationState.user?.language]}</option>
                                        </select>
                                        <div className="invalid-feedback">{Translate.KeysFormSectorFeedback[authenticationState.user?.language]}</div>
                                    </div>
                                </div>
                                {/* <div className="form-group flex-fill">
                                    <label htmlFor="contactCompanySiretInput" className="form-label fw-demi text-body text-opacity-44">{Translate.KeysFormSiret[authenticationState.user?.language]} <strong className="text-body text-opacity-100 fs-md">*</strong></label>
                                    <input type="text" className="form-control rounded-2 py-3 px-4 fw-medium bg-common bg-opacity-5 border-none text-body" id="contactCompanySiretInput" placeholder="Type here..." disabled={R.isNotNil(partnersState.partner) && showModify === false ? true : false} value={selectedPartner?.siret || ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, siret: e.target.value }); }} required />
                                    <div className="invalid-feedback">{Translate.KeysFormSiretFeedback[authenticationState.user?.language]}</div>
                                </div> */}
                            </section>
                            {R.isNil(partnersState.partner) || showModify === true ?
                                <footer className="flex-row aling-items-center justify-content-end gap-3">
                                    {R.isNotNil(partnersState.partner) && <button type="button" className="btn fw-demi fs-aux text-body p-0 text-opacity-44 text-opacity-100-hover" onClick={(e) => { e.preventDefault(); setSelectedPartner(partnersState.partner); setShowModify(false); }}>{Translate.Cancel[authenticationState.user?.language]}</button>}
                                    <button className="btn btn-add link-success fw-bold px-4 fs-aux rounded-1 flex-row align-items-center gap-2" type="submit">
                                        {Translate.Submit[authenticationState.user?.language]}
                                        {partnersState.isLoading.includes(LoadingsPartner.CREATING_PARTNER) || partnersState.isLoading.includes(LoadingsPartner.UPDATING_PARTNER) ?
                                            <div className="spinner-border spinner-border-sm align-middle" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            : null
                                        }
                                    </button>
                                </footer>
                                :
                                <button className="btn fw-demi fs-aux text-body p-0 text-opacity-44 text-opacity-100-hover mt-n2 me-auto" onClick={() => { setShowModify(true); }}>
                                    <span className="icns material-symbols-rounded align-middle">
                                        edit_note
                                    </span>
                                    <span className="align-middle mx-2">
                                        {Translate.KeysModify[authenticationState.user?.language]}
                                    </span>
                                </button>
                            }
                        </form>
                        {R.isNotNil(partnersState.partner) && showModify === false &&
                            <React.Fragment>
                                <div className={"flex-column flip-card gap-3" + (showTechnicalAccounts ? " flipped" : "") + (R.isNil(selectedPartner?.apiKey) ? " bg-none disabled" : " needs-validation")}>
                                    <ul className="nav nav-tabs border-none mb-n3">
                                        <li className="nav-item">
                                            <button type="button" className={"nav-link border-none" + (showTechnicalAccounts ? "" : " fw-semibold active")} onClick={() => { setShowTechnicalAccounts(false); }}>{Translate.KeysSecrets[authenticationState.user?.language]}</button>
                                        </li>
                                        <li className="nav-item">
                                            <button type="button" className={"nav-link border-none" + (!showTechnicalAccounts ? "" : " fw-semibold active")} onClick={() => { setShowTechnicalAccounts(true); }}>{Translate.KeyIds[authenticationState.user?.language]}</button>
                                        </li>
                                    </ul>
                                    <form className={"card card-body rounded-top-0 p-3 flex-column gap-3" + (showTechnicalAccounts ? "" : " active")} noValidate id="selectedApiKeyForm" onSubmit={submitApiKey} ref={apiKeyRef}>
                                        {/* <header className="flex-row align-items-center flex-fill gap-2">
                                            <button type="button" className="btn text-primary bg-primary bg-opacity-10 text-white-hover bg-opacity-100-hover fw-bold py-g1 fs-md rounded-1 flex-fill" onClick={() => { setShowTechnicalAccounts(true); }}>
                                                {Translate.KeyIds[authenticationState.user?.language]}
                                            </button>
                                        </header> */}
                                        <section className="flex-column gap-3 mt-1">
                                            <div className="form-group border-bottom border-top flex-fill mt-1 sr-only">
                                                <h2 className="fs-xs ls-3 fw-black ff-aux lh-1 py-2 my-auto text-body text-opacity-50 text-uppercase">{Translate.KeysSecrets[authenticationState.user?.language]}</h2>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="TechnicalAccountCredentialInput" className="form-label fw-demi text-body text-opacity-44">{Translate.PartnerId[authenticationState.user?.language]}</label>
                                                <div className="input-group rounded-2 bg-dark bg-opacity-5">
                                                    <input className="form-control py-3 px-4 fw-black bg-none border-none ls-3  text-body" id="TechnicalAccountCredentialInput" type="text" value={selectedPartner?.id || ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, id: e.target.value }); }} disabled={true} />

                                                    <button type="button" className="input-group-text fs-aux bg-none border-none text-dark-hover" onClick={(e) => { navigator.clipboard.writeText(selectedPartner?.id); toast(Translate.Copied[authenticationState.user?.language], toasterDown); }}>
                                                        <span className="icns material-symbols-rounded align-middle"> content_copy </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="KeyInput" className="form-label fw-demi text-body text-opacity-44">{Translate.Key[authenticationState.user?.language]}</label>
                                                <div className="input-group rounded-2 bg-common bg-opacity-5">
                                                    <input className="form-control py-3 px-4 fw-black bg-none border-none ls-3  text-body" id="KeyInput" type={showApiSecret === true ? "text" : "password"} value={selectedPartner?.apiKey || ''} onChange={(e) => { setSelectedPartner({ ...selectedPartner, apiKey: e.target.value }); }} disabled={true} />
                                                    <button type="button" className="input-group-text fs-aux bg-none border-none text-dark-hover" onClick={() => { setShowApiSecret(!showApiSecret); }}>
                                                        {showApiSecret === true ?
                                                            <span className="icns material-symbols-rounded align-middle"> visibility </span>
                                                            :
                                                            <span className="icns material-symbols-rounded align-middle"> visibility_off </span>
                                                        }
                                                    </button>
                                                    <button type="button" className="input-group-text fs-aux bg-none border-none text-dark-hover" onClick={(e) => { navigator.clipboard.writeText(selectedPartner?.apiKey); toast(Translate.Copied[authenticationState.user?.language], toasterDown); }}>
                                                        <span className="icns material-symbols-rounded align-middle"> content_copy </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex-row flex-md-column flex-lg-row alert bg-primary bg-opacity-10 text-primary border-none p-3 gap-3 mb-0 mt-n2">
                                                <i className="fst-normal fs-5 px-2">⚠</i>
                                                <p className="m-auto pe-xl-4 ms-0">{Translate.KeysAlert[authenticationState.user?.language]}</p>
                                            </div>
                                        </section>
                                        <footer className="flex-column aling-items-center justify-content-start gap-2">
                                            <button className="btn text-light bg-info-success bg-primary-hover fw-bold py-3 px-4 rounded-2" type="submit" disabled={R.isNotNil(selectedPartner?.apiKey) ? false : true}>
                                                {Translate.KeysGenerate[authenticationState.user?.language]}
                                                {partnersState.isLoading.includes(LoadingsPartner.FETCHING_SECRET_NEW) ?
                                                    <div className="spinner-border spinner-border-sm align-middle mx-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    : null
                                                }
                                            </button>
                                        </footer>
                                    </form>
                                    <form className={"card card-body  p-3 flex-column gap-3" + (showTechnicalAccounts ? " active" : "")} noValidate id="selectedTechnicalAccountForm" ref={technicalAccountRef}>
                                        <header className="flex-row align-items-center flex-fill gap-2 justify-content-between">
                                            <button type="button" className="btn btn-primary fw-bold py-g1 fs-md rounded-1 px-4" data-bs-toggle="modal" data-bs-target="#createTechnicalAccount">
                                                {Translate.Add[authenticationState.user?.language]}
                                            </button>
                                            <button type="button" className="btn o-44 o-100-hover p-0" aria-label="Close" onClick={() => { setShowTechnicalAccounts(false); }}>
                                                <span className="icns material-symbols-rounded align-middle lh-1 align-middle"> close </span>
                                            </button>
                                        </header>
                                        <section className="flex-column gap-3">
                                            <div className="form-group border-bottom border-top flex-fill mt-1 sr-only">
                                                <h2 className="fs-xs ls-3 fw-black ff-aux lh-1 py-2 my-auto text-body text-opacity-50 text-uppercase">{Translate.KeyIds[authenticationState.user?.language]}</h2>
                                            </div>
                                            <DataTable keyField="siren" columns={flowColumns} data={selectedPartner.technicalAccounts} progressPending={partnersState.isLoading.includes(LoadingsPartner.DELETING_TECHNICAL_ACCOUNT) || partnersState.isLoading.includes(LoadingsPartner.FETCHING_TECHNICAL_ACCOUNTS) || partnersState.isLoading.includes(LoadingsPartner.CREATING_TECHNICAL_ACCOUNTS)} selectableRows={false} fixedHeader customStyles={flowStyles} noDataComponent={
                                                <div className="container-fluid container-medium flex-column my-g2 gap-1 text-center">
                                                    <h3 className="fs-2 fw-black lh-1 ff-aux mb-0"> {Translate.itsEmpty[authenticationState.user?.language]}.</h3>
                                                    <div className="mb-2 o-77">
                                                        {Translate.unfortunately[authenticationState.user?.language]}
                                                    </div>
                                                </div>
                                            } />
                                        </section>
                                    </form>
                                </div>
                                <button type="button" className="btn fw-demi fs-aux text-body p-0 text-opacity-44 text-opacity-100-hover" onClick={handleResetConsumption}>
                                    {consumptionState.isLoading.includes(LoadingsConsumption.RESETING_CONSUMPTION) ?
                                        <div className="spinner-border spinner-border-sm align-middle mx-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        : null
                                    }
                                    {Translate.KeysReset[authenticationState.user?.language]}
                                </button>
                            </React.Fragment>
                        }
                        <ModalCreateTechnicalAccount authenticationState={authenticationState} companiesState={companiesState} partnersState={partnersState} partnerID={selectedPartner?.id} />
                        <ModalDeleteTechnicalAccount authenticationState={authenticationState} selectedTechnicalAccounts={selectedTechnicalAccounts} partnerID={selectedPartner?.id} />
                    </div>
                </article>
    );
};

export default Partner;