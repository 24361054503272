import React from 'react';
import { Link } from "react-router-dom";

export const Fallback = ({redirect, margin, onClick}) => {
    return (
        <main className={"card flex-grow-1 px-4 py-5 text-center border-0 p-2 gap-2 bg-body-tertiary" + (margin ? (" " + margin) : "")} role="main">
            <div className="container mw-base m-auto px-lg-4">
                {/* <small className="smallcaps fw-bold ff-aux">Alert !!!</small> */}
                <h2 className="display-1 fw-bold mb-3 mt-n2 ff-aux text-primary">👋 Hello!</h2>
                <p className="lead fw-normal mb-2">Something went wrong and your request is not available at the moment.</p>
                <p className="o-77 mb-6 px-4">The site configured at this address does not contain the requested file.</p>

                <Link className="btn btn-primary ff-base rounded-1 px-4" to={redirect} onClick={onClick ?  onClick : null}>Try again</Link>
            </div>
        </main>
    );
};
export default Fallback;
