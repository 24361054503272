import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/authentication';
import { authenticationActions } from '../reducers/authentication';
import { jwtDecode } from 'jwt-decode';


export function GetCodeAuthentication(code, state) {
    return async (dispatch) => {
        dispatch(authenticationActions.getCodeAuthenticationRequest());
        return new Promise((resolve, reject) => {
            axios
                .post(urls.getAuthorizationCodeUrl(), {
                    code, state,
                    validateStatus: function (status) {
                        return status < 500; // Resolve only if the status code is less than 500
                    },
                })
                .then(response => {
                    console.log(response);
                    sessionStorage.setItem("id_token", response.data.idToken);
                    sessionStorage.setItem("access_token", response.data.accessToken);
                    dispatch(authenticationActions.getCodeAuthenticationSuccess());
                    resolve(JSON.stringify(jwtDecode(response.data.accessToken)));
                })
                .catch((error) => {
                    sessionStorage.removeItem("id_token");
                    sessionStorage.removeItem("access_token");
                    dispatch(authenticationActions.getCodeAuthenticationFailure(error.response));
                    // window.location = "/404";
                    reject();
                });
        });
    };
};

export function GetUser(idToken) {
    return (dispatch) => {
        dispatch(authenticationActions.getUserRequest());
        if (R.isNil(idToken)) {
            const error = `Invalid user: ${idToken}`;
            dispatch(authenticationActions.getUserFailure(error));
            throw new Error(error);
        } else {
            dispatch(authenticationActions.getUserSuccess(jwtDecode(idToken)));
            return idToken;
        }
    };
}


