import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/partners';
import { partnersActions } from '../reducers/partners';


export function GetPartners() {
    return async (dispatch) => {
        dispatch(partnersActions.getPartnersRequest());
        return axios.get(urls.getPartnersUrl())
            .then((response) => {
                dispatch(partnersActions.getPartnersSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.getPartnersFailure(error.response));
                throw error.response;
            });
    };
}

export function GetPartner(id) {
    return async (dispatch) => {
        dispatch(partnersActions.getPartnerRequest());
        return axios.get(urls.getPartnerByIdUrl({partnerId: id}))
            .then((response) => {
                dispatch(partnersActions.getPartnerSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.getPartnerFailure(error.response));
                throw error.response;
            });
    };
}

export const CreatePartner = (partner = {}) => {
    if (R.isEmpty(partner)) {
        throw new Error(`Invalid partner`);
    }
    return async dispatch => {
        dispatch(partnersActions.createPartnerRequest());
        // setTimeout(() => {
        //     dispatch(partnersActions.createPartnerSuccess({
        //         data:   {
        //             id: Math.floor(Math.random() * 1000),
        //             name: partner.name,
        //             sector: partner.sector,
        //             apiSecret: Math.floor(Math.random() * 1000).toString(),
        //         }
        //     }));}, 3000)
        return axios.post(urls.getPartnersUrl(), partner)
            .then((response) => {
                dispatch(partnersActions.createPartnerSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.createPartnerFailure(error.response.data));
                throw error;
            });
    };
};

export function UpdatePartner(partnerId, partner = {}) {
    return async dispatch => {
        dispatch(partnersActions.updatePartnerRequest());
        // setTimeout(() => {
        //     dispatch(partnersActions.createPartnerSuccess({
        //         data:   {
        //             id: partnerId,
        //             name: partner.name,
        //             sector: partner.sector,
        //             apiSecret: partner.apiSecret,
        //         }
        //     }));}, 3000)
        return axios.put(urls.getPartnerByIdUrl({partnerId}), partner)
            .then((response) => {
                dispatch(partnersActions.updatePartnerSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.updatePartnerFailure(error.response.data));
                throw error;
            });
    };
};


export const DeletePartner = (id) => {
    if (R.isNil(id)) {
        throw new Error(`Invalid partnerID: ${id}`);
    }
    return async dispatch => {
        dispatch(partnersActions.deletePartnerRequest());
        return axios.delete(urls.getPartnerByIdUrl({partnerId: id}))
            .then((response) => {
                dispatch(partnersActions.deletePartnerSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.deletePartnerFailure(error.response.data));
                throw error;
            });
    };
};

export function GetPartnerSecretNew(id) {
    return async (dispatch) => {
        dispatch(partnersActions.getPartnerSecretNewRequest());
        return axios.get(urls.getPartnerSecretNewUrl({partnerId: id}))
            .then((response) => {
                dispatch(partnersActions.getPartnerSecretNewSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.getPartnerSecretNewFailure(error.response));
                throw error.response;
            });
    };
};

export const GetPartnerTechnicalAccounts = (id) => {
    if (R.isEmpty(id)) {
        throw new Error(`Invalid partner`);
    }
    return async dispatch => {
        dispatch(partnersActions.getPartnerTechnicalAccountsRequest());
        return axios.get(urls.getPartnerTechnicalAccountsUrl({partnerId: id}))
            .then((response) => {
                dispatch(partnersActions.getPartnerTechnicalAccountsSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.getPartnerTechnicalAccountsFailure(error.response.data));
                throw error;
            });
    };
};

export const CreatePartnerTechnicalAccounts = (id, data) => {
    if (R.isEmpty(id)) {
        throw new Error(`Invalid partner`);
    }
    return async dispatch => {
        dispatch(partnersActions.createPartnerTechnicalAccountsRequest());
        return axios.post(urls.getPartnerTechnicalAccountsUrl({partnerId: id}), data)
            .then((response) => {
                dispatch(partnersActions.createPartnerTechnicalAccountsSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.createPartnerTechnicalAccountsFailure(error.response.data));
                throw error;
            });
    };
};

export const DeletePartnerTechnicalAccount = (id, technicalAccountEncode) => {
    if (R.isNil(id)) {
        throw new Error(`Invalid partnerID: ${id}`);
    }
    if (R.isNil(technicalAccountEncode)) {
        throw new Error(`Invalid technical account: ${technicalAccountEncode}`);
    }
    return async dispatch => {
        dispatch(partnersActions.deletePartnerTechnicalAccountRequest());
        return axios.delete(urls.getPartnerTechnicalAccountByIdUrl({partnerId: id, technicalAccountEncode}))
            .then((response) => {
                dispatch(partnersActions.deletePartnerTechnicalAccountSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(partnersActions.deletePartnerFailure(error.response.data));
                throw error;
            });
    };
};