import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Constants
import Translate from '../../constants/translate';
import * as LoadingsPartner from '../../constants/loadings/partners';
import { toaster } from "../../constants/utils/toaster";

// Components: Common
import { CreatePartnerTechnicalAccounts, GetPartnerTechnicalAccounts } from '../../actions/partners-api';
import { SearchCompanies } from '../../actions/companies-api';
import { debounce } from '../../constants/utils/debounce';


export const ModalCreateTechnicalAccount = ({ partnerID, partnersState, companiesState, authenticationState }) => {
    const closeRef = useRef();
    const dispatch = useDispatch();
    const DEBOUNCE_TIME = 1000; //milliseconds

    const technicalAccountsRef = useRef();
    const [selectedPartnerTechnicalAccount, setSelectedPartnerTechnicalAccount] = useState(false);
    const filterOptions = (options) => {
        return options?.map((company) => ({
            name: company.denominationUniteLegale,
            label: `${company.denominationUniteLegale} (${company.siret})`,
            value: company.siret,
        }));
    };
    const defaultOptions = filterOptions(R.isNotNil(companiesState.companies) ? companiesState.companies : []);

    const setSearchText = debounce(DEBOUNCE_TIME);

    const handleCreateTechnicalAccount = async (e) => {
        e.preventDefault();
        try {
            await dispatch(CreatePartnerTechnicalAccounts(partnerID, { siret: selectedPartnerTechnicalAccount.value })).then(() => {
                dispatch(GetPartnerTechnicalAccounts(partnerID));
                toast.info(Translate.Success[authenticationState.user?.language], toaster);
                closeRef.current.click();
            });
        } catch (error) {
            toast.error(Translate.Error[authenticationState.user?.language], toaster);
            // console.log(error)
        }
    };

    return (
        <div className="modal" id="createTechnicalAccount" tabIndex={-1} aria-labelledby="createTechnicalAccountLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content shadow-lg rounded-sm">
                    <div className="modal-header">
                        <h5 className="modal-title small text-body-secondary" id="createTechnicalAccountLabel">{Translate.CreateKeyId[authenticationState.user?.language]}</h5>
                        <button type="button" className="btn-close fw-light shadow-none" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}>
                            <span aria-hidden="true" className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0" noValidate id="selectedPartnerForm" ref={technicalAccountsRef}>
                        <AsyncSelect className="form-control p-0 border-common bg-common flex-fill rounded-2" isClearable aria-label={Translate.search[authenticationState.user?.language]} openMenuOnFocus={true} classNamePrefix="select" isLoading={partnersState.isLoading.includes(LoadingsPartner.FETCHING_TECHNICAL_ACCOUNTS)} loadOptions={(value, callback) => {
                            setSearchText((partnerID, value) => {
                                SearchCompanies(partnerID, value)
                                    .then((res) => {
                                        callback(filterOptions(res));
                                    });
                            }, partnerID, value);
                        }} defaultOptions={defaultOptions} value={selectedPartnerTechnicalAccount} onChange={(a) => setSelectedPartnerTechnicalAccount(a)} loadingMessage={Translate.loading[authenticationState.user?.language]} noOptionsMessage={
                            () => (
                                <div className="container-fluid container-medium flex-column my-g2 gap-2 text-center">
                                    <h3 className="fs-2 fw-black lh-1 ff-aux mb-0 text-body"> {Translate.itsEmpty[authenticationState.user?.language]}.</h3>
                                    <div className="mb-2 o-77 fw-normal fs-aux">
                                        {Translate.unfortunately[authenticationState.user?.language]}
                                    </div>
                                </div>
                            )
                        } />
                    </div>
                    <div className="modal-footer flex-column justify-content-center">
                        <button type="button" className="btn btn-info rounded-pill px-g2 flex-row align-items-center gap-2" disabled={(!selectedPartnerTechnicalAccount || partnersState.isLoading.includes(LoadingsPartner.CREATING_TECHNICAL_ACCOUNTS)) ? true : false} onClick={(e) => { handleCreateTechnicalAccount(e); }}>
                            {Translate.Submit[authenticationState.user?.language]}
                        </button>
                        <button type="button" className="btn py-0 text-body text-opacity-75 text-opacity-50-hover" data-bs-dismiss="modal">{Translate.Cancel[authenticationState.user?.language]}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateTechnicalAccount;