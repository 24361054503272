import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { store } from "./reducers";



// Assets
// ==========================================
// Assets: Libraries
import "bootstrap/dist/js/bootstrap.bundle";

// Assets: Global
import "./assets/stylesheets/bootstrap.scss";


// Components
// ==========================================
// Components: Common
import { NoMatch } from "./_common/noMatch";

// Components: Views
import { App } from "./app";

const root = document.getElementById("root");

createRoot(root).render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
				<Route index path="*" element={<App />} />
                <Route path="404" element={<NoMatch margin="m-g2" redirect="/" />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);