export const Submit = {'en-EN':"Submit", 'fr-FR':"Enregistrer", 'pt-PT':"Submeter"};
export const Cancel = {'en-EN':"Cancel", 'fr-FR':"Annuler", 'pt-PT':"Cancelar"};
export const Help = {'en-EN':"Help", 'fr-FR':"Aide", 'pt-PT':"Ajuda"};
export const Logout = {'en-EN':"Logout", 'fr-FR':"Déconnexion", 'pt-PT':"Sair"};
export const ManageAccount = { 'en-EN': "Manage Your Account", 'fr-FR': "Gérer votre compte", 'pt-PT': "Gerir a sua conta" };
export const Back = {'en-EN':"Go back", 'fr-FR':"Retour", 'pt-PT':"Voltar"};
export const AddNew = {'en-EN':"Add new", 'fr-FR':"Ajouter nouveau", 'pt-PT':"Adicionar novo"};
export const Add = {'en-EN':"Add new", 'fr-FR':"Ajouter", 'pt-PT':"Adicionar"};
export const itsEmpty = { "en-EN": "Looks like it's empty.", "fr-FR": "On dirait que c'est vide.", "pt-PT": "Não encontrado." };
export const unfortunately = { "en-EN": "Unfortunately, we can't seem to find the content you're looking for.", "fr-FR": "Malheureusement, nous ne parvenons pas à trouver le contenu que vous recherchez.", "pt-PT": "Infelizmente, não conseguimos encontrar o conteúdo que você está procurando." };
export const search = { "en_GB": "Search ...", "fr_FR": "Recherchez ...", "pt_PT": "Procurar ..." };
export const loading = { "en_GB": "Loading...", "fr_FR": "En cours...", "pt_PT": "Carregamento..." };
export const Identifiers = { "en_GB": "Identifier", "fr_FR": "Identifiant", "pt_PT": "Identificador" };
export const Password = { "en_GB": "Password", "fr_FR": "Mot de passe", "pt_PT": "Palavra-passe" };