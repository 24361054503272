import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/companies';

const initState = {
    isLoading: [],
    error: undefined,
    companies: undefined,
};

const CompaniesReducer = createSlice({
    name: 'companiesState',
    initialState: { ...initState },
    reducers: {
        //#region GET COMPANIES
        getCompaniesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANIES)) {
                state.isLoading.push(Loadings.FETCHING_COMPANIES);
            }
        },
        getCompaniesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
            // Object.assign(state, payload);
            state.companies = payload;
        },
        getCompaniesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
            state.error = payload;
        },
    }
});

export const companiesActions = CompaniesReducer.actions;
export default CompaniesReducer.reducer;