export const Key = {'en-EN': "API Key", 'fr-FR':"Clé API", 'pt-PT':"Chave API"};
export const APIKeys = {'en-EN': "API Keys", 'fr-FR':"Clés API", 'pt-PT':"Chaves API"};
export const PartnerId = {'en-EN': "Partner ID", 'fr-FR':"Partenaire ID", 'pt-PT':"Conta ID"};
export const KeyId = {'en-EN': "Technical Account", 'fr-FR':"Compte technique", 'pt-PT':"Conta técnica"};
export const KeyIds = {'en-EN': "Technical Accounts", 'fr-FR':"Comptes techniques", 'pt-PT':"Contas técnicas"};
export const CreateKeyId = {'en-EN': "Create technical accounts", 'fr-FR':"Créer des comptes techniques", 'pt-PT':"Crie contas técnicas"};
export const AccessAPI = {'en-EN': "API access", 'fr-FR':"Accès API", 'pt-PT':"Acesso à API"};
export const Keys = {'en-EN': "Keys", 'fr-FR':"Clés", 'pt-PT':"Chaves"};
export const KeysWelcome = {'en-EN': "The ECHO portal allows you to obtain all the identifiers necessary to call the PPF emulator APIs.", 'fr-FR':"Le portail ECHO vous permet d’obtenir l’ensemble des identifiants nécessaires pour appeler les APIs de l’émulateur du PPF.", 'pt-PT':"O portal ECHO permite obter todos os identificadores necessários para chamar as APIs do emulador PPF."};
export const KeysDocumentation = {'en-EN': "View documentation", 'fr-FR':"Consulter la documentation", 'pt-PT':"Consultar documentação."};
export const KeysFormCompany = {'en-EN': "Company Name", 'fr-FR':"Nom", 'pt-PT':"Nome da Empresa"};
export const KeysFormSiret = {'en-EN': "Company Siret", 'fr-FR':"Siret", 'pt-PT':"Siret da Empresa"};
export const KeysFormSiretFeedback = {'en-EN': "Please enter the company siret.", 'fr-FR':"Veuillez entrer le Siret de l'entreprise.", 'pt-PT':"Por favor, insira o Siret da empresa."};
export const KeysFormCompanyFeedback = {'en-EN': "Please enter the company name.", 'fr-FR':"Veuillez entrer le raison sociale de l'entreprise.", 'pt-PT':"Por favor, insira o nome da empresa."};
export const KeysFormSector = {'en-EN': "Company Sector", 'fr-FR':"Secteur d'activité", 'pt-PT':"Setor de Atividade"};
export const KeysFormSectorList_Select= {'en-EN': "Select...", 'fr-FR': "Sélectionner...", 'pt-PT':"Selecionar..."};
export const KeysFormSectorList_Fintech= {'en-EN': "Fintech", 'fr-FR': "Fintech", 'pt-PT':"Fintech"};
export const KeysFormSectorList_Banque= {'en-EN': "Banking", 'fr-FR': "Banque", 'pt-PT':"Banco"};
export const KeysFormSectorList_Finance= {'en-EN': "Finance", 'fr-FR': "Finance", 'pt-PT':"Finanças"};
export const KeysFormSectorList_Services= {'en-EN': "Services", 'fr-FR': "Services", 'pt-PT':"Serviços"};
export const KeysFormSectorList_Industrie= {'en-EN': "Manufacturing", 'fr-FR': "Industrie manufacturière", 'pt-PT':"Indústria Manufatureira"};
export const KeysFormSectorList_Telecommunication= {'en-EN': "Information Technology", 'fr-FR': "Technologies de l'Information", 'pt-PT':"Tecnologia da Informação"};
export const KeysFormSectorList_Construction= {'en-EN': "Construction", 'fr-FR': "Construction", 'pt-PT':"Construção"};
export const KeysFormSectorList_Immobilier= {'en-EN': "Real Estate", 'fr-FR': "Immobilier", 'pt-PT':"Imobiliária"};
export const KeysFormSectorList_Énergie= {'en-EN': "Energy", 'fr-FR': "Énergie", 'pt-PT':"Energia"};
export const KeysFormSectorList_Commerce= {'en-EN': "Commerce", 'fr-FR': "Commerce", 'pt-PT':"Comércio"};
export const KeysFormSectorList_Agriculture= {'en-EN': "Agriculture and Agribusiness", 'fr-FR': "Agriculture et Agro-industrie", 'pt-PT':"Agricultura e Agroindústria"};
export const KeysFormSectorList_Transport= {'en-EN': "Transportation and Logistics", 'fr-FR': "Transport et Logistique", 'pt-PT':"Transporte e Logística"};
export const KeysFormSectorList_Tourisme= {'en-EN': "Tourism", 'fr-FR': "Tourisme", 'pt-PT':"Turismo"};
export const KeysFormSectorList_Santé= {'en-EN': "Health", 'fr-FR': "Santé", 'pt-PT':"Saúde"};
export const KeysFormSectorList_Other= {'en-EN': "Other", 'fr-FR': "Autre", 'pt-PT':"Outro"};
export const KeysFormSectorFeedback = {'en-EN': "Please enter the sector.", 'fr-FR':"Veuillez entrer le secteur", 'pt-PT':"Por favor, insira o setor."};
export const KeysModify = {'en-EN': "Modify Data", 'fr-FR':"Modifier les données", 'pt-PT':"Modificar Dados"};

export const KeysSecrets = {'en-EN': "API Identifiers", 'fr-FR':"Identifiants API", 'pt-PT':"Identificadores de API"};
export const KeysAlert = {'en-EN': "Your API key is secret and should not be shared with anyone. If the key is compromised, please generate a new one.", 'fr-FR':"Votre clé API est secrète et ne doit être partagée avec personne. Si la clé est compromise, veuiller en générer une nouvelle.", 'pt-PT':"A sua chave API é secreta e não deve ser compartilhada com ninguém. Se a chave estiver comprometida, gere uma nova."};
export const KeysGenerate = {'en-EN': "Generate a new API Key", 'fr-FR':"Générer une nouvelle clé API", 'pt-PT':"Gerar uma nova Chave de API"};
export const KeysReset = {'en-EN': "Reset PPF data", 'fr-FR':"Reinitialiser les données du PPF", 'pt-PT':"Redefinir dados PPF"};