
import * as R from "ramda";
import { jwtDecode } from 'jwt-decode';
import Translate from '../constants/translate';

export const useStorage = () => {
    const idToken = sessionStorage.getItem("id_token") || null;
    const logoutUrl = "https://accounts.securibox.eu/id/connect/endsession?idToken_hint=" + idToken;
    let firstName;
    let lastName;
    let userEmail;
    let language = Translate.default;

    if (!R.isNil(idToken)) {
        const { family_name, given_name, email, locale } = jwtDecode(idToken);
        firstName = given_name;
        lastName = family_name;
        userEmail = email;
        language = locale;
    }

    return {
        authenticationStorage: {
            user: {
                firstName,
                lastName,
                email: userEmail,
                language,
            }
        }
    };
};