import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/consumption';
import { consumptionActions } from '../reducers/consumption';



export function GetConsumption(id) {
    return async (dispatch) => {
        dispatch(consumptionActions.getConsumptionRequest());
        return axios.get(urls.getConsumptionByIdUrl({partnerId: id}))
            .then((response) => {
                dispatch(consumptionActions.getConsumptionSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(consumptionActions.getConsumptionFailure(error.response));
                throw error.response;
            });
    };
}

export function resetConsumption(id) {
    return async (dispatch) => {
        dispatch(consumptionActions.resetConsumptionRequest());
        return axios.post(urls.getConsumptionResetUrl({partnerId: id}))
            .then((response) => {
                dispatch(consumptionActions.resetConsumptionSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(consumptionActions.resetConsumptionFailure(error.response));
                throw error.response;
            });
    };
}