export const flowStyles = {
    table: {
        style: {
            "--sbx-border-opacity": "1",
            "--sbx-bg-opacity": ".45",
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            display: "flex",
            minHeight: "15em"
        },
    },
    tableWrapper: {
        style: {
            height: "100%",
        },
    },
    responsiveWrapper: {
        style: {
            flex: '1 1 auto',
        },
    },
    head: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
        },
    },
    headRow: {
        style: {
            // border: 'none',
            minHeight: "2.5rem", // override the row 2
            color: "inherit",
            borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-border-opacity))",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            '&:last-of-type': {
                borderTopLeftRadius: "var(--sbx-border-radius-sm)",
                borderTopRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "1rem",
            fontWeight: "600",
            paddingLeft: "0", // override the cell padding for data cells
            paddingRight: "0",
            color: "rgba(var(--sbx-body-color-rgb),  0.44) !important",
        },
    },
    cells: {
        style: {
            fontWeight: "500",
            fontSize: "0.9375rem",
            fontFamily: "var(--sbx-headings-font-family, Manrope)",
            paddingLeft: "0", // override the cell padding for data cells
            paddingRight: "0",
            // "&:first-of-type": {
            //     paddingLeft: "1.25rem",
            // },
        },
    },
    rows: {
        style: {
            fontSize: "0.875rem", // override the font size
            color: "var(--sbx-body-color)",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            "&:not(:last-of-type)": {
                borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-border-opacity))",
            },
            '&:first-of-type': {
                borderTopLeftRadius: "var(--sbx-border-radius-sm)",
                borderTopRightRadius: "var(--sbx-border-radius-sm)",
            },
            "&:last-of-type": {
                borderBottomLeftRadius: "var(--sbx-border-radius-sm)",
                borderBottomRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
        denseStyle: {
            minHeight: "2rem",
        },
        highlightOnHoverStyle: {
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), .55)",
            borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
            borderRadius: "var(--sbx-card-border-radius)",
            outline: ".0625rem solid rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
        },
    },
    pagination: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            border: "none",
            borderRadius: "var(--sbx-card-border-radius)",
            minHeight: "2rem"
        },
        pageButtonsStyle: {
            fill: "var(--sbx-body-color)",
            color: "var(--sbx-body-color)",
            backgroundColor: "inherit",
            "&:disabled": {
                fill: "rgba(var(--sbx-body-color-rgb), .18)",
                color: "rgba(var(--sbx-body-color-rgb), .18)",
            },
            "&:hover:not(:disabled)": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), .18)",
            },
            "&:focus": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            },
        },
    },
    progress: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "var(--sbx-body-bg)",
        },
    },
};