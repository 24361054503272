import React from "react";

export const Loading = ({color}) => {
    return (
        <div className={"loading loading-sbx m-auto " + (color ? color : "text-primary")} role="status">
            <div className="spinner spinner-loading"></div>
            <div className="spinner spinner-loading"></div>
            <div className="spinner spinner-loading"></div>
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};
