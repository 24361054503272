export const FETCHING_PARTNERS = 'FETCHING_PARTNERS';
export const FETCHING_PARTNER = 'FETCHING_PARTNER';
export const CREATING_PARTNER = 'CREATING_PARTNER';
export const UPDATING_PARTNER = 'UPDATING_PARTNER';
export const DELETING_PARTNER = 'DELETING_PARTNER';

export const FETCHING_SECRET_NEW = 'FETCHING_SECRET_NEW';
export const FETCHING_TECHNICAL_ACCOUNTS = "FETCHING_TECHNICAL_ACCOUNTS";
export const CREATING_TECHNICAL_ACCOUNTS = "CREATING_TECHNICAL_ACCOUNTS";
export const DELETING_TECHNICAL_ACCOUNT = "DELETING_TECHNICAL_ACCOUNT";
