import { EndPoints } from '../api-config';

let BASE_URL = EndPoints.backendHost;
import * as R from 'ramda';

export const getUserUrl = ({ userId }) => `${BASE_URL}/api/v1/user/${userId}`;
export const getAuthorizationCodeUrl = () => `${BASE_URL}/api/v1/authentication/authorizationcode`;
export const getAuthenticationUrl = (state) => {
    var nonce = "";
    var possible = "0123456789";
    for (var i = 0; i < 12; i++) {
        nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    const hostname = window && window.location && window.location.hostname;
    return "https://" + (EndPoints.environment === "production" ? "accounts" : "accounts-dev") + ".securibox.eu/id/connect/authorize?client_id=4da3de22-c27a-4e2c-17e4-08dc1e85e2f0&redirect_uri=" + encodeURIComponent(window.location.protocol + "//" + hostname + (R.isNil(window.location.port) || window.location.port === '' ? '' : (':' + window.location.port))) + "&response_mode=query&response_type=code&scope=openid+" + encodeURIComponent("echo/api:write echo/api:read") + "&nonce=" + nonce + (!R.isNil(state) ? ('&state=' + state) : '');
};
