import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import * as R from "ramda";

import { jwtDecode } from 'jwt-decode';
import axios from "axios";

// Assets
// ==========================================
// Assets: Libraries
import "react-toastify/dist/ReactToastify.css";
import "../assets/stylesheets/app.scss";

// Components
// ==========================================
// Components: Constants
import * as LoadingsAuthentication from "../constants/loadings/authentication";
import { getAuthenticationUrl } from "../constants/webapi/authentication";
import { toaster } from "../constants/utils/toaster";
import Translate from '../constants/translate';

// // Components: Utils
import { useStorage } from '../hooks/useStorage';
import { GetConsumption } from "../actions/consumption-api";
import { GetCodeAuthentication, GetUser } from "../actions/authentication-api";
import { GetPartners, GetPartnerTechnicalAccounts } from "../actions/partners-api";
import { GetCompanies } from "../actions/companies-api";

// Components: Common
// import { Fallback } from "./_common/fallback";
import { Loading } from "../_common/loading";
import { NoMatch } from "../_common/noMatch";
import { Layout } from "./_common/layout";

// Components: Views
import { Keys } from "./keys";



export const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { authenticationStorage } = useStorage();
    const authenticationReducer = useSelector(state => state.authenticationState);

    const authenticationState = { ...authenticationReducer, ...authenticationStorage };
    const companiesState = useSelector(state => state.companiesState);
    const consumptionState = useSelector(state => state.consumptionState);
    const partnersState = useSelector(state => state.partnersState);

    useEffect(() => {
        async function init() {
            const search = new URLSearchParams(location.search);
            const code = search.get("code");
            const session_state = search.get("session_state");

            if (R.isNotNil(code)) {
                await dispatch(GetCodeAuthentication(code, session_state));
            }
            const idToken = sessionStorage.getItem("id_token");
            const accessToken = sessionStorage.getItem("access_token");

            if (R.isNil(accessToken)) {
                window.location = getAuthenticationUrl();
            }
            try {
                const { exp } = jwtDecode(accessToken);
                const expirationDateTime = new Date(exp * 1000);
                const currentDateTime = new Date();
                if (expirationDateTime < currentDateTime) {
                    axios.defaults.headers.common["Authorization"] = "";
                    sessionStorage.removeItem("access_token");
                }
                axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
                // if (R.isNil(authenticationState.user)) {
                //     dispatch(GetUser(idToken));
                // }
                dispatch(GetPartners()).then(response => {
                    if (!R.isEmpty(response.items)) {
                        dispatch(GetPartnerTechnicalAccounts(response.items.at(-1).id));
                        dispatch(GetConsumption(response.items.at(-1).id));
                        dispatch(GetCompanies(response.items.at(-1).id));
                    }
                    navigate('/');
                });
            } catch (error) {
                // console.log(error);
                toast.error(Translate.Error[authenticationState.user?.language], toaster);
            }
        };
        init();
        // // eslint-disable-next-line

    }, []);


    if (authenticationState.isLoading.includes(LoadingsAuthentication.FETCHING_CODEAUTHENTICATION)
        || R.isNil(sessionStorage.getItem("access_token"))
    ) {
        return <Loading />;
    }


    return (
        <ErrorBoundary FallbackComponent={Loading}>
            <ToastContainer />
            <Routes>
                <Route element={<Layout authenticationState={authenticationState} partnersState={partnersState} />} >
                    <Route index element={<Keys authenticationState={authenticationState} partnersState={partnersState} companiesState={companiesState} consumptionState={consumptionState} />} />
                    <Route path="404" element={<NoMatch redirect="/" />} />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Route>
            </Routes>
        </ErrorBoundary>
    );
};

