// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';


// Components
// ==========================================
// Components: Views
import Navbar from './navbar';
import Footer from './footer';

import pixel from "../../assets/images/placeholders/torreEiffel.jpg"

export const Layout = ({ authenticationState }) => {
	const location = useLocation();
	const cardRef = useRef(null);
	const pixelSize = 250;

	useEffect(() => {
		const card = cardRef.current;
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const img = new Image();
  
		img.src = pixel; // Assuming 'pixe§l' is the image source
  
		img.onload = () => {
			// Set canvas dimensions to match the image
			canvas.width = img.width;
			canvas.height = img.height;

			// Pixelate the image
			ctx.imageSmoothingEnabled = false;
			ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
			ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, img.width / pixelSize, img.height / pixelSize);
			ctx.drawImage(canvas, 0, 0, img.width / pixelSize, img.height / pixelSize, 0, 0, canvas.width, canvas.height);
	

			// Apply the pixelated image as the background
			if (card) { card.style.backgroundImage = `url(${canvas.toDataURL()})`; }
		};
	}, []); // Run once on component mount
	
	return (
		<div className={"layout p-4 p-lg-g2 gap-4 gap-lg-g2 justify-content-lg-center aling-items-lg-center" + (location.pathname === "/" ? " grid-book" : " flex-column")}>
			<h2 className="visually-hidden">Echo</h2>
			<Navbar authenticationState={authenticationState} />
			<Outlet />
			<Footer />
		</div>
	)
};

export default Layout;