import Translate from "../translate";

export const SECTORS = [
    Translate.KeysFormSectorList_Select,
    Translate.KeysFormSectorList_Fintech,
    Translate.KeysFormSectorList_Banque,
    Translate.KeysFormSectorList_Finance,
    Translate.KeysFormSectorList_Services,
    Translate.KeysFormSectorList_Industrie,
    Translate.KeysFormSectorList_Telecommunication,
    Translate.KeysFormSectorList_Construction,
    Translate.KeysFormSectorList_Immobilier,
    Translate.KeysFormSectorList_Énergie,
    Translate.KeysFormSectorList_Commerce,
    Translate.KeysFormSectorList_Agriculture,
    Translate.KeysFormSectorList_Transport,
    Translate.KeysFormSectorList_Tourisme,
    Translate.KeysFormSectorList_Santé,
    Translate.KeysFormSectorList_Other
].reduce((acc, sector) => {
    acc[sector['en-EN']] = sector;
    return acc;
}, {});