import { createSlice } from '@reduxjs/toolkit';
import * as R from "ramda";
import * as Loadings from '../constants/loadings/partners';
import { isMock, partner } from '../constants/utils/mock';

const initState = {
    isLoading: [],
    error: undefined,
    partner: !isMock ? undefined : partner,
    partners: undefined,
};

const PartnersReducer = createSlice({
    name: 'partnersState',
    initialState: { ...initState },
    reducers: {
        //#region GET PARTNERS
        getPartnersRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_PARTNERS)) {
                state.isLoading.push(Loadings.FETCHING_PARTNERS);
            }
        },
        getPartnersSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PARTNERS);
            state.partners = { ...state.partners, ...payload };
            state.partner = payload.items[payload.items.length - 1];
        },
        getPartnersFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PARTNERS);
            state.error = payload;
        },
        //#endregion
        //#region GET PARTNER
        getPartnerRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_PARTNER)) {
                state.isLoading.push(Loadings.FETCHING_PARTNER);
            }
        },
        getPartnerSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PARTNER);
            state.partners = { ...state.partners, items: { ...state.partners.items, [state.partners.items.findIndex(p => p.id !== payload.id) + 1]: payload }} ;
            state.partner = payload;
            // state.partner = {...state.partner, ...payload};
        },
        getPartnerFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_PARTNER);
            // state.error = payload.status + " - " + payload.statusText;
            state.error = payload;
        },
        //#endregion

        //#region CREATE PARTNER
        createPartnerRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_PARTNER)) {
                state.isLoading.push(Loadings.CREATING_PARTNER);
            }
        },
        createPartnerSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_PARTNER);
            state.partners = { ...state.partners, items: { ...state.partners.items, payload }};
            state.partner = payload;
        },
        createPartnerFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_PARTNER);
        },
        //#endregion

        //#region UPDATE PARTNER
        updatePartnerRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_PARTNER)) {
                state.isLoading.push(Loadings.UPDATING_PARTNER);
            }
        },
        updatePartnerSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_PARTNER);
            state.partners = { ...state.partners, items: { ...state.partners.items, [Object.keys(state.partners.items).at(-1)]: payload }};
            // state.partners = { ...state.partners, items: state.partners.items.map(p => p.id !== payload.id ? p : payload) };
            state.partner = {...state.partner, ...payload};
        },
        updatePartnerFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_PARTNER);
            state.error = payload;
        },
        //#endregion

        //#region DELETE PARTNER
        deletePartnerRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_PARTNER)) {
                state.isLoading.push(Loadings.DELETING_PARTNER);
            }
        },
        deletePartnerSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PARTNER);
            // state.partners.items = state.partners.items.filter(partner => partner.id !== payload.id);
            state.partners.items = undefined;
            state.partner = undefined;
        },
        deletePartnerFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_PARTNER);
        },
        //#endregion

        //#region GET PARTNER SECRET NEW
        getPartnerSecretNewRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_SECRET_NEW)) {
                state.isLoading.push(Loadings.FETCHING_SECRET_NEW);
            }
        },
        getPartnerSecretNewSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SECRET_NEW);
            // state.partners = { ...state.partners, items: state.partners.items?.map(p => p.id !== payload.id ? p : {...state.partner, ...payload}) };
            state.partner = {...state.partner, ...payload};
        },
        getPartnerSecretNewFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_SECRET_NEW);
            // state.error = payload.status + " - " + payload.statusText;
            state.error = payload;
        },
        //#endregion

        //#region GET PARTNER TECHNICAL ACCOUNTS
        getPartnerTechnicalAccountsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_TECHNICAL_ACCOUNTS)) {
                state.isLoading.push(Loadings.FETCHING_TECHNICAL_ACCOUNTS);
            }
        },
        
        getPartnerTechnicalAccountsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TECHNICAL_ACCOUNTS);
            state.partner = {...state.partner, technicalAccounts: payload};
        },
        
        getPartnerTechnicalAccountsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_TECHNICAL_ACCOUNTS);
            state.error = payload;
        },
        //#endregion

        //#region CREATE PARTNER TECHNICAL ACCOUNTS
        createPartnerTechnicalAccountsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_TECHNICAL_ACCOUNTS)) {
                state.isLoading.push(Loadings.CREATING_TECHNICAL_ACCOUNTS);
            }
        },
        
        createPartnerTechnicalAccountsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_TECHNICAL_ACCOUNTS);
            state.partner = {...state.partner, technicalAccounts: payload};
        },
        createPartnerTechnicalAccountsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_TECHNICAL_ACCOUNTS);
            state.error = payload;
        },
        //#endregion

        //#region DELETE PARTNER TECHNICAL ACCOUNT
        deletePartnerTechnicalAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_TECHNICAL_ACCOUNT)) {
                state.isLoading.push(Loadings.DELETING_TECHNICAL_ACCOUNT);
            }
        },
        deletePartnerTechnicalAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_TECHNICAL_ACCOUNT);
            state.partner.technicalAccounts = state.partner.technicalAccounts.filter(technicalAccounts => btoa(technicalAccounts.username + ":" + technicalAccounts.secret) !== payload);
        },
        deletePartnerTechnicalAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_TECHNICAL_ACCOUNT);
        },
        //#endregion
    }
});

export const partnersActions = PartnersReducer.actions;
export default PartnersReducer.reducer;