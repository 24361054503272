import React, { useEffect, useRef } from 'react';
import ProgressBar from "react-customizable-progressbar";
import * as R from "ramda";


// Components
// ==========================================
// Components: Constants
import * as LoadingsConsumption from "../../constants/loadings/consumption";
import * as LoadingsPartner from "../../constants/loadings/partners";

// Components: Common
import { Loading } from "../../_common/loading";
import { Fallback } from "../_common/fallback";

// Components: Assets
import pixel from "../../assets/images/placeholders/torreEiffel.jpg"
import Logo from '../../assets/images/brand/securibox-icon.svg?react';


export const Consumption = ({ partnersState, consumptionState }) => {
	const cardRef = useRef(null);
	const pixelSize = R.isNil(partnersState.partner) ? 100 : (100 - ((R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "ApiRequest")?.amount || 0) : 0));

	useEffect(() => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		const img = new Image();
  
		img.src = pixel; // Assuming 'pixe§l' is the image source
  
		img.onload = () => {
			// Set canvas dimensions to match the image
			canvas.width = img.width;
			canvas.height = img.height;

			// Pixelate the image
			ctx.imageSmoothingEnabled = false;
			ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
			ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, img.width / pixelSize, img.height / pixelSize);
			ctx.drawImage(canvas, 0, 0, img.width / pixelSize, img.height / pixelSize, 0, 0, canvas.width, canvas.height);
			if (!R.isNil(partnersState.partner)) {
				// Set the src property once the image is loaded
				cardRef.current.style.backgroundImage = `url(${canvas.toDataURL('image/webp', 0.7)})`;
			} else {
				cardRef.current.style.backgroundImage = '';
			}
			// Optional: Clean up the image element after use
			img.onload = null;
		};
	}, [pixelSize, partnersState.partner]); // Run once on component mount

	// console.log(consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "ApiRequest"))
	
	return (
			<aside className="card bg-primary bg-opacity-5 p-4 rounded-3 h-100 card-bg" style={{minHeight: "30em", backgroundSize: "cover", backgroundPosition: "center"}} ref={cardRef}>
				<h2 className="visually-hidden">Consumption</h2>
				{	R.isNil(partnersState.partner) || R.isEmpty(partnersState.partner) ?
					<Logo style={{margin: "auto", width: "15em", height: "15em", filter: "sepia(150%) saturate(300%) brightness(15%) hue-rotate(-10deg) invert(1)", opacity: ".75"}}/>
					:
					<React.Fragment>
						<div className="flex-column flex-lg-row align-items-center gap-2">
							<div className="card text-bg-dark bg-opacity-50 border-none p-3 gap-3 mb-0 w-100 container-lg-tiny flex-row flex-md-column flex-lg-row"  style={{ backdropFilter: 'blur(1rem)'}}>
								<div className="chart">
									<ProgressBar
										radius={24}
										progress={(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "ApiRequest")?.amount || 0) : 0}
										strokeWidth={4}
										trackStrokeWidth={4}
										trackStrokeColor="rgba(var(--sbx-light-rgb), .125)"
										strokeColor="currentColor"
										className="avatar avatar-4 text-primary"
									>
										<div className="indicator">
											<ProgressBar
												radius={16}
												progress={(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "DirectoryRequest")?.amount || 0) : 0}
												strokeWidth={4}
												trackStrokeWidth={4}
												strokeColor="currentColor"
												trackStrokeColor="rgba(var(--sbx-light-rgb), .125)"
												className="text-secondary"
											>
											</ProgressBar>
										</div>
									</ProgressBar>
								</div>
								<div className="flex-column gap-1 flex-fill my-auto pe-2">
									<div className="flex-row flex-fill align-items-center gap-2">
										<p className="my-0 text-uppercase fs-xs ff-aux fw-semibold ls-3">Requêtes API</p>
										<p className="my-0 ms-auto fs-md">{(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "ApiRequest")?.amount || 0) : 0} / 100</p>
										{/* <p className="my-0 ms-auto fs-md">{R.isNil(partnersState.partner) ? 0 : 75} / 100</p> */}
									</div>
									<div className="flex-row flex-fill align-items-center gap-2">
										<p className="my-0 text-uppercase fs-xs ff-aux fw-semibold ls-3">Requêtes annuaire</p>
										<p className="my-0 ms-auto fs-md">{(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "DirectoryRequest")?.amount || 0) : 0}</p>
										{/* <p className="my-0 ms-auto fs-md">{R.isNil(partnersState.partner) ? 0 : 15}</p> */}
									</div>
								</div>
							</div>
							<div className="card text-bg-dark bg-opacity-50 border-none p-2 pe-3 w-100 w-lg-x16 flex-column gap-2" style={{ backdropFilter: 'blur(1rem)'}}>
								<div className="flex-row flex-fill align-items-center gap-2">
									<div className="avatar avatar-2 bg-light bg-opacity-10 my-0 me-auto fs-md">{(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "InvoiceReceived")?.amount || 0) : 0}</div>
									<p className="my-0 text-uppercase fs-xs ff-aux fw-semibold ls-3">Factures reçues</p>
								</div>
								<div className="flex-row flex-fill align-items-center gap-2">
									<div className="avatar avatar-2 bg-light bg-opacity-10 my-0 me-auto fs-md">{(R.isNotNil(consumptionState.statistics) && !R.isEmpty(consumptionState.statistics?.consumptions)) ? (consumptionState?.statistics?.consumptions.find(item => item.consumptionType === "InvoiceSent")?.amount || 0) : 0}</div>
									<p className="my-0 text-uppercase fs-xs ff-aux fw-semibold ls-3">Factures Envoyées</p>
								</div>
							</div>
						</div>
						<p className="position-absolute bottom-0 end-0 p-4 m-0 fs-sm"><a className="text-body o-66" target="_blank" href="https://br.freepik.com/fotos-gratis/bela-foto-vertical-da-torre-eiffel-em-um-ceu-azul-brilhante_15915470.htm" rel="noreferrer">Image de wirestock</a> chez Freepik</p>
					</React.Fragment>
				}
			</aside>
	)
};

export default Consumption;