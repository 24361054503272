import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/companies';
import { companiesActions } from '../reducers/companies';

export const GetCompanies = (partnerId, companyName) => {
    if (R.isNil(partnerId)) {
        throw new Error(`Invalid partnerID: ${partnerId}`);
    }
    return async (dispatch) => {
        dispatch(companiesActions.getCompaniesRequest());
        return axios.get(urls.getCompaniesUrl({ partnerId, companyName }))
            .then((response) => {
                dispatch(companiesActions.getCompaniesSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companiesActions.getCompaniesFailure(error.response));
                throw error.response;
            });
    };
};

export const SearchCompanies = (partnerId, companyName) => {
    return axios.get(urls.getCompaniesUrl({ partnerId, companyName }))
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error.response;
        });
};